<template>
  <div class="wrapper guide">
    <div class="page-header" style="min-height: 55vh; max-height: 360px;">
      <parallax
        class="page-header-image"
        style="background-image: url('/img/bg8.jpg')"
      >
      </parallax>
      <div class="content-center">
        <div class="row">
          <div class="col-md-9 ml-auto mr-auto">
            <h1 style="margin-top: 20px;" class="title">Using AutoLearnify</h1>
              <h2> Quick Start Guide</h2>
              <p>
                  With AutoLearnify it is easy to start studying on your Desktop,
                  Mobile, or Tablet. Here is all you need to know to get started.
              </p>
          </div>
        </div>
      </div>

    </div>

      <div class="social-line social-line-big-icons social-line-white d-none d-md-block">
          <div class="container">
              <div class="row justify-content-center">
                  <div class="col-md-2">
                      <a href="#" class="btn btn-neutral btn-icon btn-twitter btn-footer">
                          <i class="fab fa-twitter"></i>
                      </a>
                  </div>
                  <div class="col-md-2">
                      <a href="#" class="btn btn-neutral btn-icon btn-facebook btn-footer">
                          <i class="fab fa-facebook-square"></i>
                      </a>
                  </div>
                  <div class="col-md-2">
                      <a href="#" class="btn btn-neutral btn-icon btn-dribbble btn-footer">
                          <i class="fab fa-dribbble"></i>
                      </a>
                  </div>
                  <div class="col-md-2">
                      <a href="#" class="btn btn-neutral btn-icon btn-youtube btn-footer">
                          <i class="fab fa-youtube"></i>
                      </a>
                  </div>
                  <div class="col-md-2">
                      <a href="#" class="btn btn-neutral btn-icon btn-instagram btn-footer">
                          <i class="fab fa-instagram"></i>
                      </a>
                  </div>
              </div>
          </div>
      </div>

    <!--<h4>Our mission is what drives us to do everything possible to expand human learning potential.</h4>-->

        <div class="features-7 section-image" style="background-image: url('/img/bg8.jpg')">
            <div class="col-md-8 mr-auto ml-auto text-center" style="margin-top:30px; ">
                <h2 class="title">Generate Flashcards & Cloze Tests</h2>
                <h4 class="description">AutoLearnify automatically generates Flashcards with questions and answers from any English text for you.  </h4>
            </div>
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-6 px-0">
                        <div class="col-sm-12">
                            <div class="info info-horizontal">
                                <div class="icon">
                                    <i class="now-ui-icons education_atom"></i>
                                </div>
                                <div class="description">
                                    <h5 class="info-title">Generate Questions</h5>
                                    <p class="description">Click on <em>Generate Questions</em> and the AI will automatically generate 7 types of questions with answers.</p>
                                </div>
                            </div>
                            <div class="info info-horizontal">
                                <div class="icon">
                                    <i class="now-ui-icons education_paper"></i>
                                </div>
                                <div class="description">
                                    <h5 class="info-title">Generate Cloze Tests</h5>
                                    <p class="description">AutoLearnify automatically generates cloze tests for you where the most suitable gaps are created. </p>
                                </div>
                            </div>
                            <div class="info info-horizontal">
                                <div class="icon">
                                    <i class="now-ui-icons design-2_ruler-pencil"></i>
                                </div>
                                <div class="description">
                                    <h5 class="info-title">Manually Edit</h5>
                                    <p class="description">You can manually edit any question, answer, or cloze test. Click on <em>Open Cloze Editor</em> to select the gaps manually.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="image-container">
                            <img v-lazy="'/img/pages/guide/Cloze-Test-Gen-iPad-1000.png'" alt="">
                        </div>
                    </div>

                    <div class="col-md-12 text-center justify-content-center">
                        <h3 class="title">Generate Flashcards Example</h3>
                        <p class="description">AutoLearnify will automatically generate your flashcards for you.<br />
                        Navigate to <em>Generate</em> and input any English text from
                            which<br> you want your flashcards (questions with answers) generated.</p>
                        <img
                                v-lazy="'/img/pages/guide/app-GenerateQuestionsMars.png'"
                                alt="Automatic Question Generation with AutoLearnify"
                                style="max-height:350px; margin-top:20px; margin-bottom: 40px;"
                        />
                    </div>
                </div>
            </div>
        </div>


        <div class="features-6" style="margin-top: 30px;">
            <div class="container">
                <div class="row">
                    <div class="col-md-8 ml-auto mr-auto text-center">
                        <h2 class="title">Study with AutoLearnify</h2>
                        <h4 class="description">The AutoLearnify App has a learn mode that allows you to study anywhere on any device.</h4>
                    </div>
                </div>
                <div class="row" style="margin-top: -30px;">
                    <div class="col-md-4">
                        <div class="info info-horizontal">
                            <div class="icon icon-info">
                                <i class="now-ui-icons objects_spaceship"></i>
                            </div>
                            <div class="description">
                                <h5 class="info-title">Start Studying</h5>
                                <p>On the <em>Dashboard</em> select the study set you want to study and the learn mode is automatically started. </p>
                            </div>
                        </div>
                        <div class="info info-horizontal">
                            <div class="icon icon-danger">
                                <i class="now-ui-icons business_chart-pie-36"></i>
                            </div>
                            <div class="description">
                                <h4 class="info-title">Statistics</h4>
                                <p>AutoLearnify gives you easy-to-understand information in the form of graphs and charts that make it easy to track your progress. Select <em>Statistics</em> in the menu to access it.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="phone-container">
                            <img v-lazy="'/img/pages/guide/iphone12-blue-Dashboard-large.png'" />
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="info info-horizontal">
                            <div class="icon icon-success">
                                <i class="now-ui-icons objects_globe"></i>
                            </div>
                            <div class="description">
                                <h4 class="info-title">Explore</h4>
                                <p>You can access already created study sets and share your own with others. Where? Click on <em>Explore</em> in the menu</p>
                            </div>
                        </div>
                        <div class="info info-horizontal">
                            <div class="icon icon-primary">
                                <i class="now-ui-icons objects_diamond"></i>
                            </div>
                            <div class="description">
                                <h5 class="info-title">Spaced Repetition</h5>
                                <p>The learning module uses an adapted version of the Leitner system. Thus, the cards you know advance levels and will be shown less frequently.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>



      <div class="container">
        <div class="row">
          <div class="col-md-12 col-lg-9 ml-auto mr-auto"> <!-- md-12 so wider on smaller screens -->
              <!--<span
                ><ul>
                  <li>
                    At the moment you can generate questions for 6 sentences at
                    a time. However, you can wait for 8 seconds and then
                    generate questions for another paragraph.
                  </li>
                  <li>Larger texts are automatically summarized.</li>
                </ul></span
              >-->
              <!-- span otherwise IDE error -->

           <!-- <p>
              To let AutoLearnify automatically generate cloze tests, navigate to
              <em>Cloze Tests</em>.<br />
              Now you can input any English text for which you want a cloze test generated.
                <br>You can edit the gaps by clicking on 'Open cloze editor'.
            </p><!--class="mx-auto d-block"-->
            <!--<img

              src="/img/pages/guide/Cloze-Test-Gen-iPad-1000.png"
              height="370px"
            />-->


              <hr style="margin-top: 60px;">
              <div class="row">
                  <div class="col-md-8 ml-auto mr-auto text-center">
                      <h2 class="title">Productivity Tools</h2>
                  </div>
              </div>

              <div class="features-5">
                  <div class="container">
                      <div class="row">
                          <div class="col-md-5">
                              <div class="info info-horizontal">
                                  <div class="icon icon-primary icon-circle">
                                      <i class="now-ui-icons location_world"></i>
                                  </div>
                                  <div class="description">
                                      <h4 class="info-title">Promodoros</h4>
                                      <p>Beat procrastination and get focused.</p>
                                  </div>
                              </div>
                              <div class="info info-horizontal">
                                  <div class="icon icon-sec icon-circle">
                                      <i class="now-ui-icons objects_spaceship"></i>
                                  </div>
                                  <div class="description">
                                      <h4 class="info-title">Tasks & Goals</h4>
                                      <p>Use Goals & Tasks to manage your day.</p>
                                  </div>
                              </div>
                              <div class="info info-horizontal">
                                  <div class="icon icon-primary icon-circle">
                                      <i class="now-ui-icons ui-1_calendar-60"></i>
                                  </div>
                                  <div class="description">
                                      <h4 class="info-title">Calendar</h4>
                                      <p>Quickly add your most frequently used tasks and events.</p>
                                  </div>
                              </div>
                          </div>
                          <div class="col-md-7" style="margin-top: 20px;">
                              <div class="tablet-container">
                                  <img style="max-width: 110%" v-lazy="'/img/ipad2-inverted.png'" />
                              </div>
                          </div>
                      </div>
                      <!--<div style="text-align: center;">
                        <img style="max-width: 600px; margin-top: 10px;" src="/img/pages/guide/app-productivity3.png" />
                      </div>-->
                  </div>
                  <br>
              </div>

            <hr>
              <div class="features-4">
                  <div class="container">
                      <div class="row">
                          <div class="col-md-8 ml-auto mr-auto text-center">
                              <h2 class="title">More Features</h2>
                              <img style="margin-top: 20px;" src="/img/pages/guide/ui-iphone-autolearnify.jpg" />
                          </div>
                      </div>
                      <div class="row">
                          <h4>Use AutoLearnify on your PC, Mac, iPhone, Tablet, or Android</h4>
                          <p>
                              You can start the web application on any device by going to
                              <a href="#https://app.autolearnify.com">app.autolearnify.com</a>. Furthermore,
                              you can download the App on the
                              <a
                                      href="#"
                              >Android AppStore</a
                              >. A more advanced iOS version will be available soon.
                          </p>
                          <!--<img src="/img/pages/guide/ipadpro-dashboard-coffee-mockuper.png" />-->

                          <h4>Study Sets</h4>
                          <p>
                              In AutoLearnify all your learning material is organized in study
                              sets. Whether a new flashcard or cloze test is automatically
                              generated or manually created, all learn items can be found in the
                              corresponding study set.
                          </p>
                          <div style="margin-top:20px;margin-bottom: -50px;width:100%">
                                  <h3>Many more features are available</h3>
                          </div>

                          <div class="col-md-4">
                              <card type="background" raised color="transparent" style="background-image: url('/img/bg24.jpg')">
                                  <template slot="info">
                                      <div class="icon icon-white">
                                          <i class="now-ui-icons arrows-1_refresh-69"></i>
                                      </div>
                                      <div class="description">
                                          <h4 class="info-title">Collaborate</h4>
                                          <p><b>Syncing</b> makes sure that your data is the same on all your devices.</p>
                                          <!--<a href="#" class="ml-3">Find more...</a>-->
                                      </div>
                                  </template>
                              </card>
                          </div>
                          <div class="col-md-4">
                              <card type="background" raised color="transparent" style="background-image: url('/img/project16.jpg')">
                                  <template slot="info">
                                      <div class="icon icon-white">
                                          <i class="now-ui-icons media-2_note-03"></i>
                                      </div>
                                      <div class="description">
                                          <h4 class="info-title">Flashcard Speak</h4>
                                          <p>Flashcard Speak reads for you any flashcard in dozens of languages.</p>
                                          <!--<a href="#">Find more...</a>-->
                                      </div>
                                  </template>
                              </card>
                          </div>
                          <div class="col-md-4">
                              <card type="background" raised color="transparenr" style="background-image: url('/img/bg27.jpg')">
                                  <template slot="info">
                                      <div class="icon icon-white">
                                          <i class="now-ui-icons design_vector"></i>
                                      </div>
                                      <div class="description">
                                          <h4 class="info-title">Scientific Formulas</h4>
                                          <p>Add <b>LaTeX</b> formulas to learn math and other subjects.</p>
                                      </div>
                                  </template>
                              </card>
                          </div>
                      </div>
                  </div>
              </div>

            <!--<div class="section-story-overview">
              <div class="row">
                <div class="col-sm-8">
                  <ul style="padding-left:30px;">
                  <li><b>Syncing</b> makes sure that your data is the same on all your devices;
                    </li>
                  <li><b>Flashcard Speak</b> reads for you any flashcard;</li>
                  <li>Add <b>LaTeX</b> formulas to learn math and other scientific subjects;</li>
                  <li><b>Add Images</b> to your flashcards.</li>
                  </ul></p>
                </div>
              </div>
            </div>-->
            <hr>
            <!--
            Explore:
            From Apple: Use Search to find songs, albums, artists, playlists, lyrics, and more. Quickly switch between searching your own library and the Apple Music catalog. You’ll see recent searches too, along with what’s trending.
            -->




        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  Card,
  Button,
  Badge,
  InfoSection,
  FormGroupInput,
  Checkbox
} from "@/components";
import { Select, Option } from "element-ui";

export default {
  name: "about-us",
  bodyClass: "about-us",
  components: {
    Card,
    InfoSection,
    Badge,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
    [FormGroupInput.name]: FormGroupInput,
    [Select.name]: Select,
    [Option.name]: Option
  },
  data() {
    return {
      form: {
        firstName: "",
        email: "",
        speciality: ""
      }
    };
  }
};
</script>
<style>
    .section-story-overview {
        padding: 10px 0;
    }
    .section-story-overview .image-container {
        height: 370px;
        -webkit-box-shadow: none;    /* at the moment none to avoid white box */
        box-shadow: none; /* at the moment none to avoid white box */
    }
    .image-container img {
      max-height: 100%; /* for mobile */
      max-width: 100%;
        object-fit: contain;
    }
    .guide em {
        font-family: "Courier New";
        font-style: normal;
        padding-right: 3px;
        padding-left: 3px;
    }
</style>
